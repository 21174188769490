<template>
    <div class="change-password-page">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="my-5 p-3">

                        <h1>Zmiana hasła</h1>
                        <div class="mb-3 text-right">
                        <router-link :to="backLink()" >Powrót</router-link>
                        </div>

                        <div v-if="isSendReset">
                            Twoje hasło zostało zmienione.
                        </div>
                        <div v-else class="form-container">
                            <p>Zmień hasło</p>

                            <form method="post" @submit.prevent="beforeReset" action="">
                                
                                <inputPassword v-model="form.oldPassword" name="oldPassword" label="Stare hasło" :newPassword="true" 
                                        :error="validation.firstError('form.oldPassword')" />

                                <inputPassword v-model="form.password" name="password" label="Nowe hasło" :newPassword="true" 
                                        :error="validation.firstError('form.password')" />
                                
                                <inputPassword v-model="form.confirmpassword" name="confirmpassword" label="Powtórz hasło" :newPassword="true" 
                                        :error="validation.firstError('form.confirmpassword')" />
                                
                                <dismissAlert ref="errorAlert" />

                                <div class="text-center">
                                    <buttonLoader type="submit" class="btnLong" variant="success" :isLoading="inProgress" label="Zmień hasło" />
                                </div>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;
import { USER_typeAdmin, USER_typeDriver, USER_typeCustomer  } from "@/constants/constants.js";

export default {
    name: 'changePassword',
    data() { 
        return {
            inProgress: false,
            isSendReset: false,
            form: {
                oldPassword: null,
                password: null,
                confirmpassword: null
            }
        }
    },
    validators: {
        'form.oldPassword': function (value) {
            return Validator.value(value).required().minLength(8).maxLength(255);
        },
        'form.password': function (value) {
            return Validator.value(value).required().minLength(8).maxLength(255);
        },
        'form.confirmpassword': function (value) {
            return Validator.value(value).required().minLength(8).maxLength(255);
        },
        'form.confirmpassword, form.password': function (valueConfirmPassword, valuePassword) {
            if (this.inProgress || this.validation.isTouched('form.confirmpassword')) {
                return Validator.value(valuePassword).required().match(valueConfirmPassword,'Hasła nie są takie same');
            }
        }
    },
    methods: {
        beforeReset(){
            this.inProgress = true;
            this.$validate().then( this.resetPassword );
        },
        resetPassword(success){
            if (success) {
                this.$store.dispatch('auth/changePassword', this.form)
                .then( () => {
                    this.inProgress = false;
                    this.isSendReset = true;
                }).catch( error =>  {
                    this.inProgress = false;
                    this.$refs.errorAlert.alertShowDanger( error );
                });
            } else {
                this.inProgress = false;
            }
        },
        backLink() {
            switch ( this.$store.getters['auth/getUserType'] ) {
                case USER_typeAdmin: { return {name: 'AdminAccount'};  }
                case USER_typeDriver: { return {name: 'DriverAccount'};  }
                case USER_typeCustomer: { return {name: 'CustomerAccount'};  }
            }
        }
    }

}
</script>